// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "src/variables.scss";
@include mat.core();

$primary-background-color: #2751a5;
$primary-text-color: #ffffff;

$secondary-text-color: #333333;
$primary-headers-color: #46535b;

$default-color: #000000;
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

//@import "~saturn-datepicker/bundle.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
/* @include mat-core(); */

$blue-palette: (
  50: #6f93dd,
  100: #5a84d8,
  200: #4575d3,
  300: #3165ce,
  400: #2c5bba,
  500: #2751a5,
  600: #224790,
  700: #1d3d7c,
  800: #183367,
  900: #142952,
  A100: #83a3e2,
  A200: #98b2e7,
  A400: #adc2eb,
  A700: #0f1e3e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sinapsis-doctor-frontend-primary: mat.m2-define-palette($blue-palette);
$sinapsis-doctor-frontend-accent: mat.m2-define-palette(mat.$m2-pink-palette);

// The warn palette is optional (defaults to red).
$sinapsis-doctor-frontend-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$sinapsis-doctor-frontend-theme: mat.m2-define-light-theme(
  $sinapsis-doctor-frontend-primary,
  $sinapsis-doctor-frontend-accent,
  $sinapsis-doctor-frontend-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($sinapsis-doctor-frontend-theme);
  @include mat.tooltip-overrides(
    (
      container-color: transparent,
      supporting-text-color: #000000b3,
    )
  );
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  font-size: 16px;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #f8f8f8 !important;
}

@media screen and (max-width: 1200px) {
  html,
  body {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1200px) {
  html,
  body {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1300px) {
  html,
  body {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 1400px) {
  html,
  body {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1600px) {
  html,
  body {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1920px) {
  html,
  body {
    font-size: 16px !important;
  }
}

.mat-snack-bar-container.single {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

.mat-snack-bar-container.multi {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
.mat-snack-bar-container.success {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
// STYLE
mat-card {
  padding: 16px;
  box-sizing: unset !important;
}

/* .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
} */

/* Palette generated by Material Palette - materialpalette.com/light-blue/amber */

/* Import font family */
@font-face {
  font-family: "nunitoregular";
  src:
    url("../src/assets/fonts/nunito-bold-webfont.woff") format("woff2"),
    url("../src/assets/fonts/nunito-bold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "nunitoregular";
  src:
    url("../src/assets/fonts/nunito-light-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/nunito-light-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "nunitoregular";
  src:
    url("../src/assets/fonts/nunito-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/nunito-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.mat-raised-button {
  background-color: $primary-background-color !important;
  color: $primary-text-color !important;
}
.mat-raised-cancel {
  background-color: $primary-headers-color !important;
  color: $primary-text-color !important;
}

.my-form-field.mat-form-field-appearance-legacy .mat-form-field-underline,
.my-form-field.mat-form-field-appearance-legacy .mat-form-field-ripple,
.my-form-field.mat-form-field-appearance-legacy.mat-focused
  .mat-form-field-underline,
.my-form-field.mat-form-field-appearance-legacy.mat-focused
  .mat-form-field-ripple {
  background-color: #c4c4c4;
}

mat-icon {
  font-family: "Material Icons" !important;
}

// .mat-slide-toggle-bar {
//   background-color:#DBDCDD !important;
//   opacity: 1 !important;
// }

// .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
//   background-color: #2751A5;
// }

// .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
//   background-color: white;
// }

.mat-slide-toggle-bar {
  background-color: #dbdcdd;
}

.mat-slide-toggle-thumb-container {
  top: -2px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #2751a5 !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #fff !important;
}

:disabled {
  opacity: 1 !important;
  color: red;
  background-image: rgba(1, 1, 1, 1);
}

.custom-tooltips {
  font-size: 14px;
  width: auto !important;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: #ffffff;
  color: #000000b3 !important;
  padding: 10px 25px 10px 15px !important;
  white-space: pre-line !important;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: -8vh !important;

  margin-left: 0px !important;
  border-radius: 0px !important;
  border-bottom: 2px solid rgba(205, 37, 45, 1);
  position: relative;
  &::after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    border-bottom: 0.4rem solid rgba(205, 37, 45, 1);
  }
  &.right {
    overflow: initial;
    margin-bottom: 1rem;
    &:after {
      bottom: -0.4rem;
      left: 0px;
      color: rgba(205, 37, 45, 1);
      transform: rotate(180deg);
    }
  }
}

//// CONFIRM DIALOG
.confirm-dialog-container {
  .mat-dialog-container {
    border-radius: 10px;
  }
}

//// TIMEPICKER
.timepicker-dialog-container {
  .mat-dialog-container {
    margin-top: 10vh;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 2px #a7a7a7;
  }
}
.timepicker-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0);
}

// schedule-appointment-container
.schedule-appointment-container {
  .mat-mdc-dialog-surface {
    border-radius: 16px !important;
  }
}

// transfer dialog
.transfer-dialog {
  .mat-mdc-dialog-surface {
    border-radius: 16px !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

////
.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  background-color: white !important;
  color: #000000 !important;
}
h5 {
  margin: 0px !important;
  margin-bottom: 20px !important;
}
h6 {
  margin-top: 0px !important;
}
.large-button-blue {
  height: 36px !important;
  width: 100% !important;
  font-size: 14px !important;
  border-radius: 30px !important;
  background-color: #2751a5 !important;
  color: white !important;
}
.large-button-blue:disabled {
  background: rgba(0, 0, 0, 0.12) !important;
}
.large-button-blue-2 {
  height: 46px !important;
  width: 100% !important;
  font-size: 17px !important;
  border-radius: 30px !important;
  background-color: #2751a5 !important;
  color: white !important;
}
.large-button-blue-2:disabled {
  background: rgba(0, 0, 0, 0.12) !important;
}
.button-color {
  background-color: #2751a5 !important;
  color: white !important;
}
.medium-width {
  width: 80% !important;
}
.mid-width {
  width: 50% !important;
}

.red-button {
  background-color: #cd252d !important;
}

// Input styles

input {
  height: 30px !important;
}
input:disabled {
  color: #46535b !important;
}

.mat-mdc-form-field-required-marker {
  display: none !important;
}

//TODO: esta aplicando a formularios no necesarios
.mdc-text-field {
  padding: 0px !important;
  background: transparent !important;

  // input {
  //   padding-top: 8px !important;
  // }
}

.mat-date-range-input-wrapper {
  height: 30px !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}
.select-container {
  .mat-mdc-select-trigger {
    padding-top: 0px !important;
    min-height: 0px !important;
  }
}
.mat-mdc-select-trigger {
  // padding-top: 12px;
  // min-height: 30px !important;
}

select {
  min-height: 38px !important;
}

//TODO: esta aplicando a formularios no necesarios
.mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
}
///Tabs
:root .mat-mdc-tab-group,
:root .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #cd252d !important;
  --mat-tab-header-active-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mat-tab-header-active-focus-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mat-tab-header-active-hover-label-text-color: rgba(
    0,
    0,
    0,
    0.87
  ) !important;
  --mat-tab-header-active-hover-indicator-color: #cd252d !important;
  --mat-tab-header-active-focus-indicator-color: #cd252d !important;
}

//siwch

.switch-button-container {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 16px;
    color: #000;
  }
}
.switch-config-button {
  .mdc-switch__icons {
    display: none !important;
  }
  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: #5664af !important;
  }
  .mat-mdc-slide-toggle .mdc-switch:disabled .mdc-switch__track::after {
    background: #5664af !important;
  }

  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    height: 26px;
    min-width: 50px;
    border: 0px solid #5664af;
    box-sizing: border-box;
    background-color: white !important;
    border-radius: 15px;
  }
  .mat-mdc-slide-toggle .mdc-switch {
    width: 50px;
  }
  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
    width: 18px;
    height: 18px;
  }
  .mat-mdc-slide-toggle .mdc-switch--unselected:enabled .mdc-switch__track {
    height: 26px;
    min-width: 50px;
    border: 0px solid #c4cdd5;
    box-sizing: border-box;
    background-color: white !important;
    border-radius: 15px;
  }
  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: white;
  }
  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
    background: #525252;
  }
  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
    background: none;
  }
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected:enabled
    .mdc-switch__handle::after {
    background: white !important;
  }
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected:enabled
    .mdc-switch__handle {
    transform: translateX(7px) translateY(-9.5px);
    background: white !important;
  }
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--unselected:disabled
    .mdc-switch__handle {
    transform: translateX(7px) translateY(-9.5px);
    background: white !important;
  }
  .mdc-switch.mdc-switch--disabled .mdc-switch__shadow {
    background: white !important;
  }

  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--selected:enabled
    .mdc-switch__handle::after {
    background: white;
  }
}

//test-call-toggle
.test-call-toggle {
  .mdc-switch__track::before {
    background: #525252 !important;
  }
}

/// schedule-follow-up-dialog
.schedule-follow-up-dialog {
  .mat-mdc-dialog-surface {
    padding: 16px !important;
    border-radius: 16px !important;
  }
}

label {
  max-width: 100% !important;
  margin-top: 5px;
}

// others

.consultation-origin-backdrop {
  background: rgba(0, 0, 0, 0.6) !important ;
}

.avatar-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
/////////
textarea {
  width: 100% !important;
}

.mat-mdc-dialog-content {
  max-height: none !important;
}
.select-reason {
  .mat-mdc-form-field-infix {
    width: 300px !important;
  }
}

.primary-call-container {
  .mat-mdc-list {
    .mat-mdc-list-item {
      .mdc-list-item__content {
        overflow: unset !important;
      }
    }
  }
}

.affiliations-modal {
  .mat-mdc-dialog-surface {
    border-radius: 16px;
  }
}